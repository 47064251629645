.signin {
    text-align: center;
}

.signin-form, .pw-forget {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signin-form-input {
    padding: 10px;
    margin: 10px 40px;
    max-width: 700px;
    width: 100%;
}

.signin-form-button {
    color: #aaa;
    background: none;
    cursor: pointer;
    padding: 10px 100px;
    display: inline-block;
    margin: 15px 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    outline: none;
    transition: all 0.3s;
    border: 3px solid #aaa;
}

.signin-form-button:hover {
    color: #444;
    border: 3px solid #444;
}

.signin-form-button:disabled {
    color: #ccc;
    border: 3px solid #eee;
    cursor: not-allowed;
}

.password-success-message {
    color: green;
}