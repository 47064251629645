.App {
  text-align: center;
  width: 100%;
}

.landing {
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
}

.landing-banner {
  width: 100%;
}

h1 {
  font-weight: normal;
  font-size: 42px;
}

.passwords {
  list-style: none;
  padding: 0;
  font-size: 32px;
  margin-bottom: 2em;
}

.more {
  font-size: 32px;
  font-family: "Courier New", monospace;
  border: 2px solid #000;
  background-color: #fff;
  padding: 10px 25px;
}
.more:hover {
  background-color: #FDD836;
}
.more:active {
  background-color: #FFEFA9;
}

.Navbar {
  display: flex;
  height: 60px;
  background: #238be5;
  color: #fff;
  align-items: center;
  margin: 0;
  padding: 0;
}

.Navbar-item {
  padding: 15px;
  margin: 5px;
  display: inline-block;
  list-style: none;  
}

.Navbar-item a {
  color: #ddd;
  text-decoration: none;
  cursor: pointer;
}

.Navbar-item a:hover {
  color: #fff;
}

ul li:last-child {
  margin-left: auto;
}