.full-screenable-node {
    background: bisque;
    height: 100%;
    text-align: center;
    align-content: space-around;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.full-screenable-node>div {
    position: initial;
    margin-left: auto;
    margin-right: auto;
}