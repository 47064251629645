.dashboard {
    text-align: center;
}

.dashboard-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.dashboard-card {
    background-color: #eee;
    border-radius: 5px;
    padding: 20px 20px;
    margin: 30px 10px;
    min-width: 260px;
    transition: all 0.3s;
}

.dashboard-card-buttons {
    display: flex;
    justify-content: flex-end;
}

.dashboard-card-delete {
    border: none;
    background: transparent;
    font-size: 1.5em;
    cursor: pointer;
    color: #bd0303;
    transition: color 0.3s;
}

.dashboard-card-delete:hover {
    color: #ff0000;
}

.delete-modal {
    text-align: center;
}

.delete-modal-button-group {
    display: flex;
    justify-content: center;
}

.delete-modal-button {
    padding: 20px;
    display: flex;
    color: #9e0000;
    background: none;
    cursor: pointer;
    padding: 10px 50px;
    display: inline-block;
    margin: 10px 10px 20px 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    outline: none;
    transition: all 0.3s;
    border: 3px solid #9e0000;
}

.delete-modal-button:hover {
    color: #ff0000;
    border: 3px solid #ff0000;
}

.cancel-modal-button {
    color: #888;
    border: 3px solid #888;
}

.cancel-modal-button:hover {
    color: #555;
    border: 3px solid #555;
}

.dashboard-card-details {
    text-decoration: underline;
    color: #555;
    cursor: pointer;
}

.dashboard-card-details:hover {
    color: #222;
}

.dashboard-card-underline {
    text-decoration: underline;
}

.dashboard-card-edit-tag {
    font-style: italic;
    font-size: 12px;
}

.admin-dashboard{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-table {
    width: 90%;
}

.team-table {
    display: flex;
    align-items: stretch;
    flex-direction: column;
}

/*
.admin-filter-form {
    margin: 20px 100px;
    max-width: 400px;
}
*/
.admin-filter-form {
    display: flex;
}
.admin-filter-form > div {
    margin: 25px
}

.admin-filter-button {
    color: #888;
    background: none;
    cursor: pointer;
    padding: 10px 100px;
    display: inline-block;
    margin: 10px 0px 20px 0px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    outline: none;
    transition: all 0.3s;
    border: 3px solid #aaa;
}

.admin-filter-selectors {
    margin: 10px 0px;
}

.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table .thead .tr {
    background-color: #009879;
    color: #ffffff;
    text-align: center;
    display: flex;
}

.styled-table .th,
.styled-table .td {
    padding: 12px 15px;
    flex: 1;
    word-break: break-all;
}

.styled-table .tbody .tr {
    border-bottom: 1px solid #dddddd;
    display: flex;
}

.styled-table .tbody .tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table .tbody .tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.styled-table .tbody .tr:hover {
    color: #009879;
}

.event_list,
.team_list {
    padding: 20px;
}

.event_list .event_details,
.team_list .team_details {
    display: flex;
    margin-left: 15px;
    flex-direction: column;
    align-items: center;
}
.event_list .event_details h3, 
.team_list .team_details h3 {
    align-self: center;
}

.team_list .team_details dt, .team_list .team_details dd { margin: 0; padding: 0; } 
.team_list .team_details dt { float: left; margin-right: 0.3em; }
.team_list .team_details dl {
    display: flex;
}
.event_list .event_details .event_specifics,
.team_list .team_details .team_specifics {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
}
.event_list .event_details .event_specifics > div,
.team_list .team_details .team_specifics > div {
    display: flex;
    justify-content: space-between;
}
.event_list .event_details .event_specifics .key,
.team_list .team_details .team_specifics .key {
    margin-right: 25px;
}
.event_list .event_details .event_specifics .value,
.team_list .team_details .team_specifics .value {
    text-align: left;
}

.team_list .tr ,
.event_list .tr {
    align-items: center;
}

.team_list .td.actions,
.event_list .td.actions {
    display: flex;
    flex-direction: column;
}

.team_list .td.actions a:nth-child(2),
.event_list .td.actions a:nth-child(2) {
    margin-top: 10px;
}


.search_area {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search_area .major_minor_selection {
    display: flex;
    flex-direction: column;
}

.search_area .minor {
    font-weight: lighter;
}
.search_area .minor input {
    border: 1px solid black
}
.search_area .major {
    font-weight: bold;
}
.search_area .major input {
    border: 2px solid black
}

.search_area .qualifiers {
    margin-top: 25px;
}