/*
.scoring-form {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    max-width: 700px;
    width: 100%;
}

.scoring-form-spacing {
    margin: 0px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scoring-form-judge {
    margin: 32px 0px;
    margin-top: 10px;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    font-size: 15px;
}

.scoring-form-team {
    margin: 32px 0px;
    margin-top: 10px;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    font-size: 15px;
    min-width: 400px;
}

label {
    text-align: center;
}

.scoring-form-div {
    position: relative;
    margin: 38px 0px;
    margin-top: 10px;
}

.scoring-form-arrow {
    position: absolute;
    top: 14px;
    right: 15px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0 5px;
    border-color: #777 transparent transparent transparent;
}

.scoring-form-arrow:hover,
.scoring-form-arrow:focus {
    border-top-color: black;
}
*/
/*
.scoring-form-select {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 10px 15px;
    outline: 0;
    border: 0;
    border-radius: 0;
    background: #ccc;
    color: #777;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    min-width: 240px;
}

.scoring-form-select::-ms-expand {
    display: none;
}

.scoring-form-select:hover,
.scoring-form-select:focus {
    color: black;
    background: #aaa;
}

.scoring-form-select:disabled {
    opacity: 0.5;
    pointer-events: none;
}
*/

.scoring-form-time-input {
    width: 60px;
    height: 60px;
    margin: 32px 0px;
    margin-top: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
}

.scoring-form-comment-input {
    margin: 30px 0px;
    margin-top: 10px;
    border: 3px solid #ccc;
}

.scoring-form-change-input {
    margin: 30px 0px;
    margin-top: 10px;
    border: 3px solid rgb(255, 0, 0);
}

.scoring-form-button {
    color: #888;
    background: none;
    cursor: pointer;
    padding: 10px 100px;
    display: inline-block;
    margin: 5px 0px 20px 0px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    outline: none;
    transition: all 0.3s;
    border: 3px solid #aaa;
}

.scoring-form-button:hover {
    color: #444;
    border: 3px solid #444;
}

.scoring-form-button:disabled {
    color: #ccc;
    border: 3px solid #eee;
    cursor: not-allowed;
}

.scoring-form-error {
    color: red;
}

.scoring-form-penalties {
    text-align: left;
    margin: 30px 0px;
    margin-top: 10px;
}

.scoring-form-penalty-input {
    padding: 6px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.scoring-form-multiplier-number {
    width: 60px;
    height: 60px;
    margin: 10px 0px;
    border: 1px solid #ccc;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
}

.scoring-form-penalty-input>div {
    flex: 1
}

.scoring-form-penalty-input>span {
    flex: .2
}

.scoring-form-final-score {
    margin-top: 5px;
    margin-bottom: 30px;
    font-weight: bold;
}

.scoring-form-penalty-text {
    width: 300px;
    border: 1px solid #238be5;
    transition: all 0.3s;
}

.scoring-form-penalty-text:disabled {
    cursor: not-allowed;
    border: 1px solid #ccc;
}